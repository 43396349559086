/*
 * File: Root.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 02:31:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd February 2023 09:16:50
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */
import { Dispatch, SetStateAction, useEffect } from "react";
import { Outlet, useOutletContext } from 'react-router-dom';
import styles from 'Content/Main/Main.module.css';
import ToTopButton from 'Content/ToTopButton/ToTopButton';
import ScrollToTop from 'Content/ScrollToTop/ScrollToTop';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import translator from "MultiLanguage/TranslationWrapper";
import ModalSellAgreement from "Content/Modals/ModalSellAgreement/ModalSellAgreement";

type ContextType = {
    setterThemeColor: Dispatch<SetStateAction<string>>;
};

type MainPropsType = {
    themeColor: string;
    changeThemeColor: Dispatch<SetStateAction<string>>;
};

function Main(props: MainPropsType) {
    useEffect(() => {
        CookieConsent.run({
        disablePageInteraction: true,
        guiOptions: {
            consentModal: {
                layout: 'box',
                position: 'middle center',
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: 'box',
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
            analytics: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            }
        },
        language: {
            default: 'fr',
            translations: {
            fr: {
                consentModal: {
                    title: translator.decode("cookie.consentModal.title"),
                    description: translator.decode("cookie.consentModal.description"),
                    acceptAllBtn: translator.decode("cookie.common.acceptAllBtn"),
                    showPreferencesBtn: translator.decode("cookie.common.manageCookies")
                },
                preferencesModal: {
                    title: translator.decode("cookie.common.manageCookies"),
                    acceptAllBtn: translator.decode("cookie.common.acceptAllBtn"),
                    closeIconLabel: translator.decode("cookie.preferencesModal.closeIconLabel"),
                    sections: [
                        {
                            title: translator.decode("cookie.description.necessary"),
                            description: translator.decode("cookie.description.necessaryDescription"),

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: translator.decode("cookie.description.performance"),
                            description: translator.decode("cookie.description.performanceDescription"),
                            linkedCategory: 'analytics'
                        },
                        {
                            title: translator.decode("cookie.description.information"),
                            description: translator.decode("cookie.description.informationDescription")
                        }
                    ]}
                }
            }
        }
        });
    }, []);

    return(
        <div className={`${styles.view}`}>
            <ModalSellAgreement onClose={() => {}} closeModal={() => {}} themeColor={""} />
            <Outlet context={{ setterThemeColor: props.changeThemeColor}}/>
            <ToTopButton themeColor={props.themeColor}/>
            <ScrollToTop/>
        </div>
    );
};

export function useMyContext() {
    return useOutletContext<ContextType>();
};

export default Main;
