/*
 * File: PersonnalContact.tsx
 * Project: autofi-front
 * File Created: Monday, 6th March 2023 06:26:52
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 12:26:13
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useState, Fragment } from "react";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/Contact/VehicleSelectionContact/VehicleSelectionContact.module.css";
import { ContactSelect } from "Content/Contact/ContactSelect/ContactSelect";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";
import Config from "Config/Config";
import buildUrl from "build-url-ts";
import VehicleIntels from "Content/Contact/VehicleSelectionContact/VehicleIntels.type";
import Input from "Theme/Component/Input";

type VehicleSelectionContactProps = {
    vehicleIntels: VehicleIntels,
    setVehicleIntel: Function
};

export default function VehicleSelectionContact(props: VehicleSelectionContactProps) {
    const [category, setCategory] = useState<OptionType>({label: translator.decode(`contact.categoriesName.select`), value: "select"});
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [energy, setEnergy] = useState<OptionType>({label: translator.decode(`contact.energyName.select`), value: "select"});
    const [energyOptions, setEnergyOptions] = useState<string[]>([]);
    const [brand, setBrand] = useState<OptionType>({label: translator.decode(`contact.brandSelect`), value: "brandSelect"});
    const [brandOptions, setBrandOptions] = useState<OptionType[]>([]);
    const [model, setModel] = useState<OptionType>({label: translator.decode(`contact.modelSelect`), value: "modelSelect"});
    const [modelOptions, setModelOptions] = useState<OptionType[]>([]);
    const [mileage, setMileage] = useState<number>();

    useEffect(() => {
        fetch(`${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CAR_CATEGORIES}`).then(async (res) => {
            const tmpCat = await res.json();
            setCategoryOptions(["select", ...tmpCat]);
        });
    }, []);

    useEffect(() => {
        setEnergyOptions([]);
        setEnergy({label: translator.decode(`contact.energyName.select`), value: "select"});
        if (category.value != "select") {
            const myVehicleIntels = {...props.vehicleIntels};
            myVehicleIntels.category = category.value;
            props.setVehicleIntel(myVehicleIntels);
            var url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CAR_ENERGIES}`;
            const myUrl = buildUrl(url, {queryParams: {filters: category.value}});
            if (myUrl)
                url = myUrl;
            fetch(url).then(async (res) => {
                const tmpEnergies: string[] = (await res.json()).map((x:string) => x.toLowerCase());
                setEnergyOptions(["select", ...tmpEnergies]);
            });
        }
    }, [category]);

    useEffect(() => {
        setBrandOptions([]);
        setBrand({label: translator.decode(`contact.brandSelect`), value: "brandSelect"});
        if (energy.value != "select") {
            const myVehicleIntels = {...props.vehicleIntels};
            myVehicleIntels.energy = energy.value;
            props.setVehicleIntel(myVehicleIntels);
            var url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CAR_BRANDS}`;
            const myUrl = buildUrl(url, {queryParams: {filters: `${category.value},${energy.value}`}});
            if (myUrl)
                url = myUrl;
            fetch(url).then(async (res) => {
                const tmpBrands = await res.json();
                setBrandOptions([translator.decode(`contact.brandSelect`), ...tmpBrands]);
            });
        }
    }, [energy]);

    useEffect(() => {
        setModelOptions([]);
        setModel({label: translator.decode(`contact.modelSelect`), value: "modelSelect"});
        if (brand.value != "brandSelect") {
            const myVehicleIntels = {...props.vehicleIntels};
            myVehicleIntels.brand = brand.value;
            props.setVehicleIntel(myVehicleIntels);
            var url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CAR_MODELS}`;
            const myUrl = buildUrl(url, {queryParams: {filters: `${category.value},${energy.value},${brand.value}`}});
            if (myUrl)
                url = myUrl;
            fetch(url).then(async (res) => {
                const tmpModels = await res.json();
                setModelOptions(["modelSelect", ...tmpModels]);
            })
        }
    }, [brand]);

    useEffect(() => {
        if (model.value != "modelSelect") {
            const myVehicleIntels = {...props.vehicleIntels};
            myVehicleIntels.model = model.value;
            props.setVehicleIntel(myVehicleIntels);
        }
    }, [model]);


    useEffect(() => {
        if (mileage) {
            const myVehicleIntels = {...props.vehicleIntels};
            myVehicleIntels.mileage = mileage;
            props.setVehicleIntel(myVehicleIntels);
        }
    }, [mileage]);

    return (
        <Fragment>
            <div className={`${styles.titleHolder}`}>
                <h3 className={`${styles.title}`}>{translator.decode("contact.vehicleIntels")}</h3>
            </div>
            <div className={`${styles.lineBlock}`}>
                <div className={`${styles.inputBlock}`}>
                    <label className={`${styles.input}`} htmlFor="category">{translator.decode("contact.category")}:</label>
                    <ContactSelect className={`${styles.inputSelect}`} id="category" value={category} onChange={(e: OptionType) => {setCategory(e);}} options={categoryOptions} contactSubCategory="contact.categoriesName" />
                </div>
                <div className={`${styles.inputBlock}`}>
                    <label className={`${styles.input}`} htmlFor="energy">{translator.decode("contact.energy")}:</label>
                    <ContactSelect className={`${styles.inputSelect}`} id="energy" value={energy} onChange={(e: OptionType) => {setEnergy(e);}} options={energyOptions} contactSubCategory="contact.energyName" />
                </div>
            </div>
            <div className={`${styles.lineBlock}`}>
                <div className={`${styles.inputBlock}`}>
                    <label className={`${styles.input}`} htmlFor="brand">{translator.decode("contact.brand")}:</label>
                    <ContactSelect className={`${styles.inputSelect}`} id="brand" value={brand} onChange={(e: OptionType) => {setBrand(e.value != "brandSelect" ? e : {label: translator.decode(`contact.brandSelect`), value: "brandSelect"});}} options={brandOptions} />
                </div>
                <div className={`${styles.inputBlock}`}>
                    <label className={`${styles.input}`} htmlFor="model">{translator.decode("contact.model")}:</label>
                    <ContactSelect className={`${styles.inputSelect}`} id="model" value={model} onChange={(e: OptionType) => {setModel(e.value != "modelSelect" ? e : {label: translator.decode(`contact.modelSelect`), value: "modelSelect"});}} options={modelOptions} />
                </div>
            </div>
            <div className={`${styles.inputBlock}`}>
                <label className={`${styles.input}`} htmlFor="mileage">{translator.decode("contact.mileage")}:</label>
                <Input className={`${styles.inputSize} ${styles.inputNumber}`}
                        id="km" type="number" min={0} max={1000000} step={500} name="mileage" placeholder={translator.decode("contact.mileage")}
                        value={mileage} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMileage(parseInt(e.target.value))} />
            </div>
            <h6 className={`${styles.title}`}>{translator.decode("contact.vehicleIntelsSubtitle")}</h6>
        </Fragment>
    );
}
