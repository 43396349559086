/*
 * File: PasswordRenewal.tsx
 * Project: autobuyclient
 * File Created: Monday, 2nd October 2023 08:53:53
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 2nd October 2023 04:29:59
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useLoaderData } from "react-router-dom"
import PasswordConfirm from "../PasswordConfirm/PasswordConfirm";
import { useLang } from "Router/LangueProvider";

const PasswordRenewal = () => {
    const {translator} = useLang();
    const token = useLoaderData() as string;

    return (
        <PasswordConfirm token={token} renewal={true}>
            <p>{translator.decode("password.renewal")}</p>
        </PasswordConfirm>
    )
}

export default PasswordRenewal;
