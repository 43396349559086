/*
 * File: Root.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 02:33:29
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd February 2023 09:16:54
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { ReactNode, useState } from 'react';
import Footer from 'Content/Footer/Footer';
import Header from 'Content/Header/Header';
import Main from 'Content/Main/Main';
import styles from 'Content/Root/Root.module.css';

function Root(props: {children: ReactNode[]}) {
    const [themeColor, setThemeColor] = useState("");

    return(
        <div className={`${styles.view}`}>
            <Header themeColor={themeColor} changeThemeColor={setThemeColor} />
            <Main themeColor={themeColor} changeThemeColor={setThemeColor}/>
            <Footer themeColor={themeColor} />
        </div>
    );
};

export default Root;
