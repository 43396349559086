/*
 * File: CookiePathResolver.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import { CookieConfig } from "./CookiePathResolver.type";

const COOKIE_CONFIG: CookieConfig = {
  USER: {
    FIELD_USER: "user/user_id",
    ROLE: "user/role",
    TOKEN: "user/token",
    REFRESH_TOKEN: "user/refresh",
  }
};

export default COOKIE_CONFIG;
