/*
 * File: Input.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";
import Select from "react-select";

/**
* Styled component Input
* Type: input (provided via props)
*/

const mySelect = styled(Select).attrs((props: any) => (
{
    options: props.options,
    className: props.className,
    // className: `medium_font_2 ${props.className}`,
    placeholder: props.placeholder,
    isDisabled: (props.disabled ?? false)
    // isDisabled: ((props.options !== undefined && props.options?.length > 1 ? false : true) || (props.disabled ?? false))
}
))`
color: var(--text-color-first);
// background-color: ${(props: any) => props.theme.colors.first};
font-size: 0.9rem;
letter-spacing: 0.5px;
border-radius: 5px;
// overflow: hidden;
border: solid 1px rgba(0, 0, 0, 0.10);
// border-bottom: 1px solid ${props => props.theme.colors.first};
&:hover, &:focus {
    outline: none;
    background-color: ${(props: any) => props.theme.colors.first};
}
`;

export default mySelect;
