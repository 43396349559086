/*
 * File: CarService.ts
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:55:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 02:45:37
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import ServiceGeneral from "Services/ServiceGeneral";

type ContactRequest = {
  carIntels?: {
    id?: number;
    category?: string;
    energy?: string;
    brand?: string;
    model?: string;
    mileage?: number;
  };
  personnalIntel: {
    gender?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: {
      houseNumber?: string;
      street?: string;
      postalCode: string;
      city?: string;
    };
  };
  comments: {
    text: string;
  };
};

export default class ContactService {
  static addNewContactRequest(contact: ContactRequest) {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CONTACT}`;
    return ServiceGeneral.manageCall(url, "post", contact);
  };

  static phoneCallBackRequest(phone: string) {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CONTACT}/phone`;
    return ServiceGeneral.manageCall(url, "post", {phone});
  };
}
