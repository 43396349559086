/*
 * File: UserSession.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import Cookie from "Cookies/CookieWrapper";
import CookiePathResolver from "Config/Cookies/CookiePathResolver";
import Config from "Config/Config";
import Axios from "axios";

/**
 * Class used to manage session user in cookies
 */
class UserSession {
  /**
   * Get The user informations as an object
   * @returns The info of the user connected
   */
  static getUser() {
    return Cookie.getCookie(CookiePathResolver.USER.FIELD_USER);
  }

  /**
   * Get the token of the user
   * @returns The token as a string
   */
  static getAccessToken() {
    return Cookie.getCookie(CookiePathResolver.USER.TOKEN);
  }

  /**
   * Get the refresh token of the user
   * @returns The token as a string
   */
  static getRefreshToken() {
    return Cookie.getCookie(CookiePathResolver.USER.REFRESH_TOKEN);
  }


  /**
   * Set the tokens of the users
   * @param {Object} tokens The tokens of the user
   */
  static setTokens(tokens: {
    access_token: { token: string; expires_at: string };
    refresh_token: { token: string; expires_at: string };
  }) {
    Cookie.setCookie(CookiePathResolver.USER.TOKEN, tokens.access_token, Config.COOKIE.COOKIE_TIME_DAYS);
    if (tokens.refresh_token !== undefined && tokens.refresh_token !== null)
      Cookie.setCookie(CookiePathResolver.USER.REFRESH_TOKEN, tokens.refresh_token, Config.COOKIE.COOKIE_TIME_REFRESH_DAYS);
  }

  /**
   * Get the role of the authenticated user, null otherwise
   * @returns The role of the authenticated user, null otherwise
   */
  static getRole() {
    return Cookie.getCookie(CookiePathResolver.USER.ROLE);
  }

  /**
   * Check if the current session is active
   * @returns True if logged, false otherwise
   */
  static isUserLoggedIn() {
    const tokens = Cookie.getCookie(CookiePathResolver.USER.TOKEN);
    if (tokens !== undefined)
      return true;
    return false;
  }

  /**
   * Remove cookies of the authenticated user
   */
  static signOut() {
    Cookie.removeCookie(CookiePathResolver.USER.TOKEN);
    Cookie.removeCookie(CookiePathResolver.USER.REFRESH_TOKEN);
  }

  static async tokenExpired() {
    const refrToken = this.getRefreshToken();
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.LOGIN}${Config.BACKEND.PATHS.REFRESH}`;
    const resp = await Axios.post(url, {refresh_token: refrToken}).catch(() => {
      return false;
    });
    if (!(typeof resp === "boolean") && resp.status === 200) {
      UserSession.setTokens({
        access_token: resp.data.token.access_token,
        refresh_token: resp.data.token.refresh,
      });
      return true;
    }
    return false;
  }

  static async buildAxiosHeader() {
    const token = this.getAccessToken();
    if (!token) {
      const refresh = this.getRefreshToken();
      if (refresh) {
        const result = await this.tokenExpired();
        if (result) {
          const basicHeader = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.getAccessToken()}`
            }
          };
          return basicHeader;
        }
      } else
        return {};
    }
    const basicHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };
    return basicHeader;
  }
}

export default UserSession;
