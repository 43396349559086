/*
 * File: CarService.ts
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:55:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 5th May 2023 05:28:36
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import { CarListable } from "Content/Car/CarList/CarList.type";
import { buildUrl } from "build-url-ts";
import { SingleCarFormat } from "Content/Car/CarElement/CarElement.type";
import ServiceGeneral from "Services/ServiceGeneral";

type QueryParams = { [key: string]: string | string[] };
type RespCarDbType = {
  cars: string[];
  nb_page: number;
  brand: string;
};

export default class CarService {
  static async getCars(page: number, filters: string[] | null | undefined, orderBy: { name: string; order: string } | undefined, isPromo?: boolean | undefined) {
    const a: QueryParams = {};
    filters = filters?.filter((val: string) => val != "");
    if (page > 0) a.page = page.toString();
    if (filters != null && filters != undefined && filters.length > 0)
      a.filters = filters.toString();
    if (
      orderBy != undefined &&
      orderBy.name != "" &&
      orderBy.order != "" &&
      orderBy.order != "notActivated"
    ) {
      let name = orderBy.name;
      switch (orderBy.name) {
        case "sell_price":
          name = "SellPrice";
          break;
        case "mileage":
          name = "Mileage";
          break;
        case "circulation_date":
          name = "CirculationDate";
          break;
        case "rental_cost":
          name = "RentalCost";
          break;
        case "id":
          name = "id";
          break;
        default:
          break;
      }
      a.orderBy = name + "," + orderBy.order;
    }
    if (isPromo) a.isPromo = isPromo.toString();

    const builtUrl = buildUrl(
      `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CAR}`,
      {
        queryParams: {
          page: a.page,
          filters: a.filters,
          orderedby: a.orderBy,
          isPromo: a.isPromo,
        },
      }
    );
    if (builtUrl) {

      const myList: any = await ServiceGeneral.manageCall(builtUrl, "get");
      if (myList.data.cars == undefined)
        return { nb_page: 0, cars: [], brand: "" };
      return CarService.transformCarList(myList.data);
    } else {
      return;
    }
  }

  static async transformCarList(list: RespCarDbType) {
    let myCars: CarListable[] = [];
    let nbPages = 0;
    let brand = "";
    if (list.cars != undefined) {
      myCars = list.cars.map((x: any) => {
        const tmp2 = new CarListable();
        tmp2.fromJson(x);
        return tmp2;
      });
      nbPages = list.nb_page;
    }
    if (list.brand != undefined) brand = list.brand;

    return { nb_page: nbPages, cars: myCars, brand: brand };
  }

  static transformSingleCar(car: any): SingleCarFormat | null {
    if (car == undefined || car == null)
      return null;
    const newCar = new SingleCarFormat();
    newCar.fromJson(car);
    return newCar;
  }

  static async getSingleCar(id: string): Promise<any> {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CAR}/${id}`;
    const singleCar = await ServiceGeneral.manageCall(url, "get");
    const mySingleCar = this.transformSingleCar(singleCar.data);
    return mySingleCar;
  }

  static getPicturesFirstCar(id: string) {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_PICTURE}${id}/0`;
    return ServiceGeneral.manageCall(url, "get");
  }

  static getPicturesForOneCar(id: string) {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_PICTURE}${id}`;
    return ServiceGeneral.manageCall(url, "get");
  }

  static getEquipementsForOneCar(id: string) {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.GET_CAR_EQUIPMENT}${id}`;
    return ServiceGeneral.manageCall(url, "get");
  }

  static async getFavoriteCars() {
    const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.FAVORITES}`;
    const myRet = await ServiceGeneral.manageCall(url, "get");
    return myRet.data;
  }

  static setQueryParams(filters: string[]) {
    const a: QueryParams = {};
    if (filters != null && filters.length! > 0) a.filters = filters.toString();
    const myParams = buildUrl("", {
      queryParams: {
        filters: a.filters,
      },
    });
    return myParams;
  }

  static async getCarCategoriesAvailable(): Promise<string[]> {
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_CATEGORIES
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarEnergiesAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_ENERGIES + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarBrandsAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_BRANDS + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarModelsAvailable(filters: string[]): Promise<string[]> {
    const myParams = this.setQueryParams(filters);
    const tmp = await fetch(
      Config.BACKEND.BASEURL + Config.BACKEND.PATHS.GET_CAR_MODELS + myParams
    );
    const myRet: string[] = await tmp.json();
    return myRet;
  }

  static async getCarsByBrand(
    brand: string,
    page: number | null,
    orderBy: { name: string; order: string }
  ): Promise<any> {
    const a: QueryParams = {};

    if (page != null && page > 0) a.page = page.toString();
    if (
      orderBy.name != "" &&
      orderBy.order != "" &&
      orderBy.order != "notActivated"
    ) {
      let name = orderBy.name;
      switch (orderBy.name) {
        case "sell_price":
          name = "SellPrice";
          break;
        case "mileage":
          name = "Mileage";
          break;
        case "circulation_date":
          name = "CirculationDate";
          break;
        case "rental_cost":
          name = "RentalCost";
          break;
        default:
          break;
      }
      a.orderBy = name + "," + orderBy.order;
    }
    const myParams = buildUrl("", {
      queryParams: {
        page: a.page,
        orderedby: a.orderBy,
      },
    });

    const tmp = await fetch(
      Config.BACKEND.BASEURL +
        Config.BACKEND.PATHS.GET_CARS_BY_BRAND +
        brand +
        myParams
    );
    const myRet = await tmp.json();

    return this.transformCarList(myRet);
  }

 static addCarFav(carID: string) {
  const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CAR}/favorite/${carID}`;
  return ServiceGeneral.manageCall(url, "post");
 }

 static isFavorite(carID: string) {
  const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CAR}/favorite/${carID}`;
  return ServiceGeneral.manageCall(url, "get");
 }

}
