/*
 * File: Input.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";

/**
* Styled component Input
* Type: input (provided via props)
*/
const Input = styled.input.attrs((props: any) => ({
    ...props,
    className: "medium_font_2 extra-lineheight-1",
}))`
text-align: ${props => props.align};
padding-right: 10px;
padding-left: 10px;
// color: ${(props: any) => props.variant === "positive" ? props.theme.colors.fifth : (props.variant === "negative" ? props.theme.colors.fifth : (props.variant === "none" ? "var(--theme-bg-color)" : props.theme.colors.third))};
background-color: ${(props: any) => props.variant === "positive" ? props.theme.colors.sixth : (props.variant === "negative" ? props.theme.colors.second: (props.variant === "warning" ? props.theme.colors.seventh : (props.variant === "none" ? props.theme.colors.transparent : props.theme.colors.first)))};
border-top: 0px;
border-left: 0px;
border-right: 0px;
border-bottom: 1px solid ${props => props.theme.colors.first};
&:hover, &:focus {
    outline: none;
    background-color: ${(props: any) => props.variant == "none" ? props.theme.colors.transparent : props.theme.colors.fifth};
}
`;

export default Input;
